<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">车载HMI开发</h1>
				<p data-caption-delay="100" class="wow fadeInRight">为客户提供从低端到高端的全链路座舱开发解决方案</p>
				<div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<section class="solution-intros preevision2x-solution-intro wow fadeInLeft">
			<div class="container">
				<el-row>
					<el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<div class="intro-text">
								<p>东信创智提供的智能座舱开发解决方案包含仪表HMI Framework框架和工具，基于 Qt 的Cluster/HUD APP，IVI/HVAC APP等座舱功能开发。
								</p>
							</div>
							<div class="intro-text">
								<p>仪表HMI Framework框架彻底解耦了UI界面、UE逻辑、业务逻辑，可兼容多个系统；对从低端到高端硬件平台进行广泛支持。</p>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
						<img src="../../../assets/images/solution-hmi-intro.png" alt=""
							style="display: block;width: 100%;margin: 0 auto;">
					</el-col>
				</el-row>
			</div>
			<div class="left-bg">
				<img src="../../../assets/images/index-about-bg-01.png" alt="">
			</div>
			<div class="right-bg">
				<img src="../../../assets/images/index-about-bg-02.png" alt="">
			</div>
		</section>
		<section
			class="vehicle-solution-sections solution-functions electric-solution-function wow fadeInRight">
			<div class="container">
				<div class="normal-title">功能特点</div>
				<el-row>
					<el-col>
						<div class="function-intro">
							<p>东信创智的 HMI 整合架构具有完备的功能和灵活的硬件支持，以实现最佳的性能优化和出色的用户体验。
							</p>
						</div>
					</el-col>
				</el-row>
				<div class="all-function" @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
					@mouseleave="$refs.mySwiper.swiper.autoplay.start()">
					<swiper :options="swiperOption" ref="mySwiper">
						<swiper-slide v-for="(item, index) in MSILFunctionList" :key="index">
							<div class="card-item">
								<el-card class="solution-function-card" shadow="hover">
									<div class="card-icon">
										<img :src=" item.icon "
											:style="'width:' + item.width +';padding-top:' + item.padding"
											alt="">
									</div>
									<div class="card-text">
										<p>{{ item.content }}</p>
									</div>
								</el-card>
							</div>
						</swiper-slide>
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</swiper>
				</div>
			</div>
		</section>

		<section class="dyna-product-system-picture wow fadeInRight">
			<div class="container">
				<div class="normal-title">应用领域</div>
				<div class="all-system-picture">
					<div class="system-picture">
						<img src="../../../assets/images/solution-hmi-application-pic.png" alt="" style="width: 80%;height: 80%">
					</div>
				</div>
			</div>
		</section>
		<section class="network-solution-system-picture wow fadeInRight"  style="height: 810px">
			<div class="container">
				<div class="normal-title">系统图片</div>
				<div class="all-system-picture">
					<div class="system-picture">
						<img src="../../../assets/images/solution-hmi-system-pic.png" alt="">
					</div>
					<div class="system-picture">
						<img src="../../../assets/images/solution-hmi-system-pic02.png" alt="">
					</div>
				</div>
			</div>
		</section>
		<bzInfo style="margin-top: 0" />
		<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from '@/components/MainNav'
	import mainFooter from '@/components/MainFooter'
	import bzInfo from '@/components/BzInfo'
	import MSILFunctionPic1 from '@/assets/images/solution-msil-function-icon-1.svg'
	import MSILFunctionPic2 from '@/assets/images/solution-msil-function-icon-2.svg'
	import MSILFunctionPic3 from '@/assets/images/solution-msil-function-icon-3.svg'
	import MSILFunctionPic4 from '@/assets/images/solution-msil-function-icon-4.svg'
	import MSILFunctionPic5 from '@/assets/images/solution-msil-function-icon-5.svg'
	import MSILFunctionPic6 from '@/assets/images/solution-msil-function-icon-6.svg'
	export default {
		name: "hmi",
		components: {
			mainNav,
			mainFooter,
			bzInfo
		},
		data() {
			return {
				activeIndex: '2-4',
				tabFixed: false,
				activeProduct: '1',
				MSILFunctionList: [{
						icon: MSILFunctionPic1,
						width: "55px",
						padding: "10px",
						content: "一站式全链路的工具和服务"
					},
					{
						icon: MSILFunctionPic2,
						width: "58px",
						padding: "15px",
						content: "完整技术栈的开发能力"
					},
					{
						icon: MSILFunctionPic3,
						width: "53px",
						padding: "15px",
						content: "丰富的性能优化经验"
					},
					{
						icon: MSILFunctionPic4,
						width: "55px",
						padding: "10px",
						content: "全面专业的平台移植"
					},
					{
						icon: MSILFunctionPic5,
						width: "55px",
						padding: "12px",
						content: "支持低端到高端的硬件平台"
					},
					{
						icon: MSILFunctionPic6,
						width: "50px",
						padding: "15px",
						content: "一致的应用层框架，降低适配成本"
					},
					{
						icon: MSILFunctionPic1,
						width: "50px",
						padding: "15px",
						content: "支持各类操作系统/无系统运行"
					},{
						icon: MSILFunctionPic2,
						width: "50px",
						padding: "15px",
						content: "支持AUTOSAR，支持各类车载场景"
					},
				],
				swiperOption: {
					spaceBetween: 10, // 图片之间的间距
					centeredSlides: false, // 居中还是从图1开始
					slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
					breakpointsInverse: true,
					breakpoints: {
						//当宽度大于等于1100
						1100: {
							slidesPerView: 4,
							spaceBetween: 10
						}
					},
					notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
					// loop: true, // 循环吗
					initialSlide: 0, // 从第几个开始
					autoplay: {
						delay: 5000, // 等5秒下一个
						disableOnInteraction: false // 中间滑动一下，取消自动吗？
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}, // 下按钮
					speed: 800, // 滑动时候动画的速度
					paginationClickable: true, // 下面按钮让点吗
					navigation: {
						prevEl: '.swiper-button-prev', // 左侧按钮
						nextEl: '.swiper-button-next' // 右侧按钮
					},
					// effect: 'fade', // 渐入效果
					watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
					watchSlidesVisibility: true // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
					// autoHeight: true  // 图片高度自适应
				},
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
			}
		},
		mounted() {
			new this.$wow.WOW().init();
			document.title = "车载HMI开发 - 解决方案 - 东信创智";
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
		}
	}
</script>
